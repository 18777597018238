import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';

import { ClientHelmet } from '../components';
import { FormPageLayout } from '../components/Layouts';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Title, Paragraph } = Typography;
const GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR = process.env.GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR!;
const strings = new LocalizedStrings({
    en: {
        authNotFound: 'Auth module not found',
        successNotif:
            'Please check your inbox for a password reset link. If you did not receive any email, please check your Spam folder.',
        forgotPassword: 'Forgot Virtooally Password',
        enterAccountDetails: 'Enter your account details below.',
        email: 'E-mail',
        enterEmail: 'Please enter your email!',
        sendRecovery: 'Send recovery link',
        backToLogin: 'Back to login',
    },
    de: {
        authNotFound: 'Authentifizierung konnte nicht erfolgen',
        successNotif:
            'Bitte überprüfen Sie Ihr Postfach. Wir haben Ihnen eine E-Mail mit dem Zurücksetzungslink für Ihr Passwort gesendet. Bitte überprüfen Sie ggf. auch Ihren Spam-Ordner. ',
        forgotPassword: 'Virtooally-Passwort vergessen',
        enterAccountDetails: 'Bitte geben Sie Ihre Daten ein',
        email: 'E-Mail-Adresse',
        enterEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        sendRecovery: 'Zurücksetzungslink senden',
        backToLogin: 'Zurück zum Login',
    },
});

const ForgotPasswordPage = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const { eventAuthModule, fetchEvent } = context;
    const breakpoint = useBreakpoint();

    useEffect(() => {
        fetchEvent().catch(handleError);
    }, []);

    const onFinish = async ({ emailId }: { emailId: string }) => {
        try {
            setLoading(true);
            if (!eventAuthModule) throw new Error(strings.authNotFound);
            const response = await apiRequester.forgotPassword({ eventAuthModule, emailId });
            handleSuccess(strings.successNotif!);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };
    return (
        <FormPageLayout>
            <ClientHelmet />
            <div style={{ marginBottom: '2rem' }}>
                <Title
                    level={2}
                    style={{ marginBottom: '0', color: 'white', textAlign: breakpoint.md ? 'left' : 'center' }}
                >
                    {strings.forgotPassword}
                </Title>
                <Paragraph style={{ color: 'white', textAlign: breakpoint.md ? 'left' : 'center' }}>
                    {strings.enterAccountDetails}
                </Paragraph>
            </div>
            <Form onFinish={onFinish}>
                <Form.Item
                    label={<span style={{ color: 'white' }}>{strings.email}</span>}
                    labelCol={{ span: 24 }}
                    name="emailId"
                    rules={[{ required: true, type: 'email', message: strings.enterEmail }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: breakpoint.md ? 'row' : 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            style={{
                                borderColor: GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR,
                                marginBottom: breakpoint ? '1rem' : undefined,
                            }}
                        >
                            {strings.sendRecovery}
                        </Button>
                        <Link
                            style={{ color: 'white' }}
                            to={`/login${typeof window !== 'undefined' ? window.location.search : ''}`}
                        >
                            <ArrowLeftOutlined /> {strings.backToLogin}
                        </Link>
                    </div>
                </Form.Item>
            </Form>
        </FormPageLayout>
    );
};

export default ForgotPasswordPage;
